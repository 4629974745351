<template>
  <a-spin :spinning="spinning" size="large">
    <a-card title="基础信息" class="iss-card" style="margin-bottom: 10px">
      <a-form
        ref="tagType"
        layout="vertical"
        :model="tagType"
        :rules="tagTypeRules"
        :validate-trigger="['change', 'blur']"
      >
        <a-row :gutter="20">
          <a-col :sm="12" :md="8" :lg="6">
            <a-form-item name="tagTypeCode" label="编码">
              <a-input
                v-model:value="tagType.tagTypeCode"
                placeholder="请输入编码"
              />
            </a-form-item>
          </a-col>
          <a-col :sm="12" :md="8" :lg="6">
            <a-form-item name="typeName" label="名称">
              <a-input
                v-model:value="tagType.typeName"
                placeholder="请输入名称"
              />
            </a-form-item>
          </a-col>
          <a-col :sm="12" :md="8" :lg="6">
            <a-form-item name="type" label="类别">
              <a-input v-model:value="tagType.type" placeholder="请输入类型" />
            </a-form-item>
          </a-col>
          <a-col :sm="12" :md="8" :lg="6">
            <a-form-item name="subType" label="子类">
              <a-input
                v-model:value="tagType.subType"
                placeholder="请输入子类"
              />
            </a-form-item>
          </a-col>
          <a-col :sm="24" :md="16" :lg="24">
            <a-form-item name="typyDesc" label="描述">
              <a-textarea
                v-model:value="tagType.typyDesc"
                placeholder="请输入描述"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-form
        ref="tagStrategy"
        :model="tagStrategy"
        :rules="tagStrategyRules"
        layout="vertical"
      >
        <a-row :gutter="20">
          <a-col :sm="24" :md="12">
            <a-form-item
              name="strategyType"
              label="更新方式"
              extra="目前只支持手动执行"
            >
              <a-radio-group v-model:value="tagStrategy.strategyType">
                <a-radio value="one">
                  单次计算(仅在保存标签后计算一次)
                </a-radio>
                <a-radio value="daily"> 每日计算(每日24点更新一次) </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :sm="24" :md="12">
            <a-form-item name="sourceType" label="数据范围" ref="sourceType">
              <a-radio-group
                v-model:value="tagStrategy.sourceType"
                class="iss-radio"
              >
                <div class="item">
                  <a-radio value="base">基础用户：</a-radio>
                  <a-select
                    v-model:value="tagStrategy.targetType"
                    placeholder="请选择基础用户"
                    :options="userTypeList"
                    @blur="() => $refs.sourceType.onFieldBlur()"
                    @change="() => $refs.sourceType.onFieldChange()"
                  />
                </div>
                <div class="item">
                  <a-radio value="activity">活动用户：</a-radio>
                  <a-select
                    v-model:value="tagStrategy.activityCode"
                    placeholder="请选择活动"
                    @blur="() => $refs.sourceType.onFieldBlur()"
                    @change="() => $refs.sourceType.onFieldChange()"
                  >
                    <a-select-option
                      v-for="item in activityIdNameList"
                      :key="item.id"
                    >
                      {{ item.campaignName }}
                    </a-select-option>
                  </a-select>
                </div>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
    <a-card title="标签规则" class="iss-card" style="margin-bottom: 10px">
      <template #extra>
        <a-button type="primary" ghost @click="visible = true">
          <template #icon><PlusOutlined /></template>
          新增分层
        </a-button>
      </template>
      <a-tabs
        class="iss-tabs"
        v-model="activeKey"
        hide-add
        type="editable-card"
        @change="handleTabChange"
        @edit="handleTabEdit"
      >
        <a-tab-pane
          v-for="pane in tagDetailVoList"
          :key="pane.tagCode"
          :tab="pane.tagName"
        />
      </a-tabs>
      <div class="iss-regulations" v-if="activeKey">
        <!--分群规则 start-->
        <ul class="condition-group-list">
          <!--条件组列表 -->
          <li
            class="condition-group-item"
            :key="index"
            v-for="(item, index) in activeConditionList"
          >
            <div class="condition-group-type-wrapper">
              <a class="and-btn"></a>
            </div>
            <ul class="condition-list">
              <!--条件列表 -->
              <li
                class="condition-item"
                :key="ruleIndex"
                v-for="(rule, ruleIndex) in item.ruleList"
              >
                <div class="condition-type-wrapper">
                  <a class="or-btn"></a>
                </div>
                <div class="condition-wrapper">
                  <table class="condition-table">
                    <!--属性满足-->
                    <tr v-if="rule.type === 'property'">
                      <td style="width: 14%">
                        <span class="condition-type"> 属性满足 </span>
                        <a
                          class="remove-btn"
                          @click="removeRule(index, ruleIndex)"
                        />
                      </td>
                      <td style="width: 18%">
                        <a-select
                          v-model:value="rule.name"
                          placeholder="请选择属性"
                          @change="handleChangeProperty($event, rule)"
                        >
                          <a-select-option
                            v-for="p in propertyList"
                            :key="p.colCode"
                            :value="p.colCode"
                          >
                            {{ p.colName }}
                          </a-select-option>
                        </a-select>
                      </td>
                      <td style="width: 15%">
                        <a-select
                          v-model:value="rule.operator"
                          placeholder="请选择操作符"
                        >
                          <a-select-option
                            v-for="o in operatorListMap[rule.dataType]"
                            :key="o.symbol"
                            :value="o.symbol"
                          >
                            {{ o.symbolName }}
                          </a-select-option>
                        </a-select>
                      </td>
                      <td v-if="rule.name === 'country'" style="width: 53%">
                        <el-autocomplete
                          style="width: 50%"
                          v-if="rule.operator !== '('"
                          v-model="rule.value"
                          :fetch-suggestions="
                            (queryString, cb) =>
                              cb(
                                areaOptions.filter(item =>
                                  item.value.includes(queryString)
                                )
                              )
                          "
                          placeholder="请输入..."
                        />
                      </td>
                      <td v-else-if="rule.name === 'region'" style="width: 53%">
                        <el-autocomplete
                          style="width: 50%"
                          v-if="rule.operator !== '('"
                          v-model="rule.value"
                          :fetch-suggestions="
                            (queryString, cb) =>
                              cb(
                                areaDeptOptions.filter(item =>
                                  item.value.includes(queryString)
                                )
                              )
                          "
                          placeholder="请输入地区部..."
                        />
                      </td>
                      <td
                        v-else-if="rule.name === 'industry_lv1'"
                        style="width: 53%"
                      >
                        <el-autocomplete
                          style="width: 50%"
                          v-if="rule.operator !== '('"
                          v-model="rule.value"
                          :fetch-suggestions="
                            (queryString, cb) =>
                              cb(
                                industryLv1Options.filter(item =>
                                  item.value.includes(queryString)
                                )
                              )
                          "
                          placeholder="请输入行业..."
                        />
                      </td>
                      <td
                        v-else-if="rule.name === 'customer_type'"
                        style="width: 53%"
                      >
                        <el-autocomplete
                          style="width: 50%"
                          v-if="rule.operator !== '('"
                          v-model="rule.value"
                          :fetch-suggestions="
                            (queryString, cb) =>
                              cb(
                                CUSTOMETYPEOptions.filter(item =>
                                  item.value.includes(queryString)
                                )
                              )
                          "
                          placeholder="请输入客户类型..."
                        />
                      </td>
                      <td
                        v-else-if="rule.name === 'job_title'"
                        style="width: 53%"
                      >
                        <el-autocomplete
                          style="width: 50%"
                          v-if="rule.operator !== '('"
                          v-model="rule.value"
                          :fetch-suggestions="
                            (queryString, cb) =>
                              cb(
                                JobTitleOptions.filter(item =>
                                  item.value.includes(queryString)
                                )
                              )
                          "
                          placeholder="请输入客户类型..."
                        />
                      </td>
                      <td
                        v-else-if="rule.name === 'is_activate'"
                        style="width: 53%"
                      >
                        <a-select
                          v-if="rule.operator !== '('"
                          v-model="rule.value"
                          style="width: 50%"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in is_activateOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </a-select>
                      </td>
                      <td
                        v-else-if="rule.dataType === 'time'"
                        style="width: 53%"
                      >
                        <el-date-picker
                          v-if="rule.operator !== '('"
                          v-model="rule.value"
                          type="datetime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                        />
                        <el-date-picker
                          v-if="
                            ['()', '[)', '(]', '[]'].includes(rule.operator)
                          "
                          v-model="rule.secondValue"
                          type="datetime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                        />
                      </td>
                      <td v-else style="width: 53%">
                        <a-textarea
                          v-if="rule.operator !== '('"
                          v-model:value="rule.value"
                          auto-size
                          placeholder="请输入..."
                        />
                        <a-input
                          v-if="
                            ['()', '[)', '(]', '[]'].includes(rule.operator)
                          "
                          v-model:value="rule.secondValue"
                          placeholder="请输入..."
                        />
                      </td>
                    </tr>
                    <!--行为满足-->
                    <tr v-else-if="rule.type === 'behavior'">
                      <td style="width: 14%">
                        <span class="condition-type"> 行为满足 </span>
                        <a
                          class="remove-btn"
                          @click="removeRule(index, ruleIndex)"
                        />
                      </td>
                      <td style="width: 18%">
                        <a-select
                          v-model:value="rule.name"
                          placeholder="请选择行为"
                        >
                          <a-select-option
                            v-for="ap in actionPropertyList"
                            :key="ap.colCode"
                            :value="ap.colCode"
                          >
                            {{ ap.colName }}
                          </a-select-option>
                        </a-select>
                      </td>
                      <td style="width: 15%">
                        <a-select
                          v-model:value="rule.action"
                          placeholder="请选择功能"
                        >
                          <a-select-option
                            v-for="b in behaviorAllList"
                            :key="b.value"
                            :value="b.value"
                          >
                            {{ b.label }}
                          </a-select-option>
                        </a-select>
                      </td>
                      <td style="width: 18%">
                        <a-select
                          v-model:value="rule.operator"
                          placeholder="请选择操作符"
                        >
                          <a-select-option
                            v-for="ol in operatorList"
                            :key="ol.value"
                            :value="ol.value"
                          >
                            {{ ol.label }}
                          </a-select-option>
                        </a-select>
                      </td>
                      <td style="width: 35%">
                        <a-input
                          v-model:value="rule.value"
                          placeholder="请输入..."
                        />
                      </td>
                    </tr>
                    <!--行为满足2-->
                    <tr v-if="rule.type === 'behavior'">
                      <td></td>
                      <td colspan="3">
                        <a-range-picker
                          v-model:value="rule.secondValueArray"
                          style="width: 100%"
                          show-time
                          value-format="yyyy-MM-dd HH:mm:ss"
                          @change="changeBehaviorTime(index, ruleIndex)"
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </li>

              <!--条件添加按钮 -->
              <li class="condition-item">
                <div class="condition-type-wrapper">
                  <a class="or-btn"></a>
                  <div class="condition-type-list">
                    <a
                      class="condition-type-a"
                      @click="addCondition('property', index)"
                    >
                      属性满足
                    </a>
                    <a
                      class="condition-type-a"
                      @click="addCondition('behavior', index)"
                    >
                      行为满足
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </li>
          <!--条件组添加按钮 -->
          <li class="condition-group-item">
            <div class="condition-group-type-wrapper">
              <a class="and-btn"></a>
              <div class="condition-type-list">
                <a
                  class="condition-type-a"
                  @click="addConditionGroup('property')"
                >
                  属性满足
                </a>
                <a
                  class="condition-type-a"
                  @click="addConditionGroup('behavior')"
                >
                  行为满足
                </a>
              </div>
            </div>
          </li>
        </ul>
        <!--分群规则 end-->
      </div>
    </a-card>
    <div class="ta-right">
      <a-space>
        <a-button type="primary" @click="handleSave"> 保存 </a-button>
        <a-button @click="$router.go(-1)"> 返回 </a-button>
      </a-space>
    </div>
  </a-spin>
  <a-modal
    :visible="visible"
    title="新增"
    :maskClosable="false"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <a-form
      ref="tagForm"
      :model="tagForm"
      :rules="tagFormRules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 14 }"
      :validate-trigger="['change', 'blur']"
    >
      <a-form-item name="tagCode" label="编码">
        <a-input v-model:value="tagForm.tagCode" placeholder="请输入编码" />
      </a-form-item>
      <a-form-item name="tagName" label="名称">
        <a-input v-model:value="tagForm.tagName" placeholder="请输入名称" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import {
  Card,
  Form,
  Row,
  Col,
  Radio,
  Select,
  Space,
  Spin,
  Tabs,
  DatePicker,
} from 'ant-design-vue';
import { PlusOutlined } from '@ant-design/icons-vue';
import tagApi from '@/api/tag.js';
import integralApi from '@/api/integral.js';

export default {
  components: {
    ACard: Card,
    AForm: Form,
    AFormItem: Form.Item,
    ARow: Row,
    ACol: Col,
    ARadioGroup: Radio.Group,
    ARadio: Radio,
    ASelect: Select,
    ASelectOption: Select.Option,
    ASpace: Space,
    ASpin: Spin,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ARangePicker: DatePicker.RangePicker,
    PlusOutlined,
  },
  data() {
    const required = { required: true, message: '不能为空' };

    return {
      code: '',
      spinning: false,
      tagType: {},
      tagTypeRules: {
        tagTypeCode: [required],
        typeName: [required],
        type: [required],
        subType: [required],
      },
      tagStrategy: {},
      tagStrategyRules: {
        strategyType: [required],
        sourceType: [
          required,
          {
            validator: (rule, value) => {
              if (value === 'base') {
                return this.tagStrategy.targetType
                  ? Promise.resolve()
                  : Promise.reject('请选择基础用户');
              } else if (value === 'activity') {
                return this.tagStrategy.activityCode
                  ? Promise.resolve()
                  : Promise.reject('请选择活动');
              } else {
                return Promise.resolve();
              }
            },
          },
        ],
      },
      tagForm: {},
      tagFormRules: {
        tagName: required,
        tagCode: [
          required,
          {
            validator: (rule, value) => {
              const vo = this.tagDetailVoList.find(
                item => item.tagCode === value
              );
              return vo
                ? Promise.reject('编码不能重复，请重新输入')
                : Promise.resolve();
            },
          },
        ],
      },
      userTypeList: [
        { value: 'allMember', label: '会员用户' },
        { value: 'allContact', label: '联系人' },
        { value: 'allLeads', label: '线索数据' },
      ],
      activityIdNameList: [],
      visible: false,
      tagDetailVoList: [],
      activeKey: '',
      activeConditionList: [],
      propertyList: [],
      operatorListMap: {},
      actionPropertyList: [], //动作属性
      behaviorAllList: [
        { label: '总次数', value: 'count' },
        { label: '总量', value: 'total' },
      ],
      operatorList: [
        { label: '大于', value: '>' },
        { label: '小于', value: '<' },
        { label: '等于', value: '=' },
      ],
      areaOptions: [],
      areaDeptOptions: [],
      industryLv1Options: [],
      is_activateOptions: [
        { value: '0', label: '未激活' },
        { value: '1', label: '待激活' },
        { value: '2', label: '已激活' },
      ],
      CUSTOMETYPEOptions: [],
      JobTitleOptions: [],
    };
  },
  created() {
    //await this.getAreaOptions();
    // const areaDeptRes = await integralApi.getAreaDeptOptions();
    // this.areaDeptOptions = Object.values(
    //   areaDeptRes.data.AREA_DEPT
    // ).map(item => ({ value: item }));
    // const INDUSTRYRes = await integralApi.getIndustryLv1Options();
    // this.industryLv1Options = Object.values(
    //   INDUSTRYRes.data.INDUSTRY
    // ).map(item => ({ value: item }));
    // const CUSTOMETYPERes = await integralApi.getCUSTOMETYPEOptions();
    // this.CUSTOMETYPEOptions = Object.values(
    //   CUSTOMETYPERes.data.CUSTOMETYPE
    // ).map(item => ({ value: item }));
    // const JobTitleRes = await integralApi.getJobTitleOptions();
    // this.JobTitleOptions = Object.values(
    //   JobTitleRes.data['Job Title']
    // ).map(item => ({ value: item }));

    this.initTagDetails();
    this.initActivityIdNameList();
    this.initPropertySelector();
    this.initPropertyRule();
    this.initActionSelector();
  },
  methods: {
    initTagDetails() {
      const id = this.$route.params.id;
      if (id) {
        if (id === 'add') {
          this.code = 'tagmanage:add';
        } else {
          this.code = 'tagmanage:update';
          tagApi.getTagDetails(this.code, id).then(data => {
            this.tagType = data.tagType || {};
            this.tagStrategy = data.tagStrategy || {};
            this.tagDetailVoList = data.tagDetailVoList || [];
            if (this.tagDetailVoList.length) {
              this.handleTabChange(this.tagDetailVoList[0].tagCode);
            }
          });
        }
      }
    },
    initActivityIdNameList() {
      tagApi.getActivityIdNameList(this.code).then(data => {
        this.activityIdNameList = data;
      });
    },
    initPropertySelector() {
      tagApi.getPropertySelector(this.code).then(({ contact }) => {
        this.propertyList = contact;
      });
    },
    initPropertyRule() {
      tagApi.getPropertyRule(this.code).then(data => {
        this.operatorListMap = data;
      });
    },
    initActionSelector() {
      tagApi.getActionSelector(this.code).then(({ xingwei }) => {
        this.actionPropertyList = xingwei;
      });
    },
    handleSave() {
      const { tagType, tagStrategy } = this.$refs;
      tagType.validate().then(() => {
        tagStrategy.validate().then(() => {
          if (this.tagDetailVoList.length) {
            this.spinning = true;
            tagApi
              .updateDetail(this.code, {
                tagType: this.tagType,
                tagStrategy: this.tagStrategy,
                tagDetailVoList: this.tagDetailVoList,
              })
              .then(() => {
                this.$message.success(this.$t('tips.updateSuccess'));
                this.$router.go(-1);
              })
              .finally(() => {
                this.spinning = false;
              });
          } else {
            this.$message.warning('请配置标签规则');
          }
        });
      });
    },
    handleOk() {
      this.$refs.tagForm.validate().then(() => {
        this.activeKey = this.tagForm.tagCode;
        this.activeConditionList = [];
        this.tagDetailVoList.push({
          ...this.tagForm,
          subType: this.tagType.subType,
          tagType: this.tagType.type,
          conditionList: this.activeConditionList,
        });
        this.handleCancel();
      });
    },
    handleCancel() {
      this.visible = false;
      this.$refs.tagForm.resetFields();
    },
    handleTabChange(activeKey) {
      const vo = this.tagDetailVoList.find(item => item.tagCode === activeKey);
      this.activeKey = activeKey;
      this.activeConditionList = vo.conditionList;
    },
    handleTabEdit(targetKey, action) {
      if (action === 'remove') {
        const index = this.tagDetailVoList.findIndex(
          item => item.tagCode === targetKey
        );
        this.tagDetailVoList.splice(index, 1);
        if (this.activeKey === targetKey) {
          const length = this.tagDetailVoList.length;
          if (length) {
            this.handleTabChange(this.tagDetailVoList[length - 1].tagCode);
          } else {
            this.activeKey = '';
          }
        }
      }
    },
    addConditionGroup(type) {
      this.activeConditionList.push({ ruleList: [] });
      this.addCondition(type, this.activeConditionList.length - 1);
    },
    addCondition(type, index) {
      this.activeConditionList[index].ruleList.push({
        action: null,
        name: null,
        operator: null,
        value: '',
        dataType: '',
        type,
      });
    },
    removeRule(index, ruleIndex) {
      this.activeConditionList[index].ruleList.splice(ruleIndex, 1);
      if (!this.activeConditionList[index].ruleList.length) {
        this.activeConditionList.splice(index, 1);
      }
    },
    handleChangeProperty(value, rule) {
      const temp = this.propertyList.find(i => i.colCode === value);
      rule.dataType = temp.dataType;
    },

    // 获取地区选择列表
    async getAreaOptions() {
      const res = await integralApi.getAreaOptions();
      const areas = Object.keys(res.data.AREA);
      this.areaOptions = areas.map(key => ({
        en: key,
        value: res.data.AREA[key],
      }));
    },

    areaChEntransformation(area, targetLanguage) {
      if (targetLanguage === 'Ch') {
        const areaObj = this.areaOptions.find(areaObj => areaObj.en === area);
        return areaObj ? areaObj.value : area;
      }

      if (targetLanguage === 'En') {
        const areaObj = this.areaOptions.find(
          areaObj => areaObj.value === area
        );
        return areaObj ? areaObj.en : area;
      }
    },

    //新增或修改
    saveTagDetail() {
      const loading = this.$loading({
        lock: true,
        text: 'loading…',
        background: 'rgba(0, 0, 0, 0.4)',
      });

      // 格式化标签群组国家数据 英文 =》 中文
      this.segment.conditionGroupList.map(group => {
        group.ruleList.map(rule => {
          if (rule.name !== 'country') return;
          Object.assign(rule, {
            value: this.areaChEntransformation(rule.value, 'En'),
          });
        });
      });
      // 格式化标签分层国家数据 英文 =》 中文
      this.segment.tagDetailVoList.map(tagDetailVo => {
        tagDetailVo.conditionList.map(condition => {
          condition.ruleList.map(rule => {
            if (rule.name !== 'country') return;
            Object.assign(rule, {
              value: this.areaChEntransformation(rule.value, 'En'),
            });
          });
        });
      });

      tagApi
        .updateDetail(this.code, this.segment)
        .then(() => {
          // let data = response.data.data;
          this.$message({
            message: '成功',
            type: 'success',
          });
          loading.close();
          this.$router.back();
        })
        .catch(error => {
          loading.close();
          console.error(error);
          this.loading = false;
        });
    },

    changeBehaviorTime(index, conditionIndex) {
      let secondValueArray = this.segment.conditionGroupList[index].ruleList[
        conditionIndex
      ].secondValueArray;
      this.segment.conditionGroupList[index].ruleList[
        conditionIndex
      ].secondValue = secondValueArray
        ? secondValueArray[0] + '@@' + secondValueArray[1]
        : '';
    },
  },
};
</script>

<style lang="less" scoped>
.iss-radio {
  display: flex;
  flex-direction: column;
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    :deep(span:nth-child(2)) {
      padding-right: 0;
    }
  }
}

.iss-tabs :deep(.ant-tabs-bar) {
  margin-bottom: 0;
}

.iss-regulations {
  padding: 10px 16px;
  border: 1px solid #e8e8e8;
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 4px 4px;
}

.iss-regulations .condition-group-item {
  display: flex;
  padding-bottom: 30px;
}
.iss-regulations .condition-group-type-wrapper {
  position: relative;
}
.iss-regulations .condition-group-type-wrapper:before {
  content: '';
  position: absolute;
  display: block;
  top: 36px;
  bottom: -30px;
  left: 50%;
  width: 3px;
  transform: translateX(-50%);
  background-color: #9fe5ef;
}

.iss-regulations
  .condition-group-item:last-child
  .condition-group-type-wrapper:before {
  display: none;
}

.iss-regulations .and-btn {
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  color: #fff;
  text-align: center;
  background-color: #3bc7db;
  border-radius: 100%;
  vertical-align: middle;
  cursor: pointer;
}

.iss-regulations .and-btn:before {
  content: 'And';
  position: relative;
  display: inline-block;
}

.iss-regulations .condition-group-item:first-child .and-btn:before {
  content: 'A';
}

.iss-regulations .condition-group-item:last-child .and-btn:before {
  content: '+';
  font-size: 30px;
  top: -2px;
}

.iss-regulations .remove-btn {
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  color: #fff;
  text-align: center;
  background-color: rgb(253, 0, 0);
  border-radius: 100%;
  margin-left: 5px;
  font-size: 12px;
  cursor: pointer;
}

.iss-regulations .remove-btn:before {
  content: '✕';
  position: relative;
  display: inline-block;
}

.iss-regulations .condition-group-item:first-child .remove-btn:before {
  content: '✕';
}

.iss-regulations .condition-group-item:last-child .remove-btn:before {
  content: '✕';
  font-size: 30px;
  top: -2px;
}

.iss-regulations .condition-type-list {
  display: none;
  vertical-align: middle;
}

.iss-regulations .condition-type-wrapper:hover .condition-type-list,
.iss-regulations .condition-group-type-wrapper:hover .condition-type-list {
  display: inline-block;
}

.iss-regulations .condition-type-a {
  display: inline-block;
  width: auto;
  height: 24px;
  line-height: 24px;
  padding: 0px 12px;
  border-radius: 24px;
  color: #3bc7db;
  background-color: #f3f9fb;
}

.iss-regulations .condition-type-a:hover {
  color: #fff;
  background-color: #3bc7db;
  text-decoration: none;
}

.iss-regulations .or-btn {
  display: inline-block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  color: #a1a6aa;
  text-align: center;
  background-color: #edf2f5;
  border-radius: 100%;
  vertical-align: middle;
  cursor: pointer;
}

.iss-regulations .condition-item:last-child .or-btn {
  color: #99a0a6;
  background-color: #fff;
  border: 1px solid #edf2f5;
}

.iss-regulations .or-btn:before {
  content: 'Or';
  position: relative;
  display: inline-block;
}

.iss-regulations .condition-item:first-child .or-btn:before {
  content: 'O';
}

.iss-regulations .condition-item:last-child .or-btn:before {
  content: '+';
  font-size: 26px;
  top: -2px;
}

.iss-regulations .condition-list {
  padding-left: 30px;
  width: 100%;
}

.iss-regulations .condition-item {
  display: flex;
}

.iss-regulations .condition-wrapper {
  width: 100%;
  white-space: nowrap;
}

.iss-regulations .condition-type-wrapper {
  position: relative;
  padding-top: 15px;
}

.iss-regulations .condition-type-wrapper:before {
  content: '';
  position: absolute;
  display: block;
  top: 10px;
  bottom: -30px;
  left: 50%;
  width: 1px;
  transform: translateX(-50%);
  background-color: #ebeff1;
}

.iss-regulations .condition-item:last-child .condition-type-wrapper:before {
  display: none;
}

.iss-regulations .condition-table {
  width: 100%;
}

.iss-regulations .condition-table td {
  padding: 30px 5px 0 5px;
}

.iss-regulations .condition-type {
  display: inline-block;
  color: #39c5d6;
  padding-left: 10px;
}

.iss-regulations .condition-close {
  cursor: pointer;
}

.condition-table .ant-select {
  width: 100%;
}
</style>
